import React from 'react'
import { Redirect, Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { API, graphqlOperation, Auth, Hub } from 'aws-amplify'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'

import { getUser } from './graphql/queries'
import { createUser } from './graphql/mutations'

import HomePage from './pages/HomePage'
import ProfilePage from './pages/ProfilePage'
import BlockPage from './components/blocks/BlockPage'
import ProjectPage from './components/project/ProjectPage'
import StudioPage from './components/studio/StudioPage'
import IntroPage from './pages/IntroPage'
import ImportPage from './pages/ImportPage'
import NotFoundPage from './pages/NotFoundPage'
import ValidatePage from './pages/ValidatePage'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import AuthForm from './components/auth/AuthForm'

import Loading from './components/ui/Loading'

import './loader.js'
import './App.css'

export const history = createBrowserHistory()
export const UserContext = React.createContext()

class App extends React.Component {
  state = {
    started: false,
    user: null,
    userAttributes: null,
    clickedLogin: false
  }

  componentDidMount() {
    this.getUserData()
    Hub.listen('auth', this.onHubCapsule)
  }

  componentWillUnmount() {
    Hub.remove('auth', this.onHubCapsule)
  }

  onHubCapsule = async ({ payload: { event, data }}) => {
    switch (event) {
      case 'signIn':
        await this.getUserData()
        await this.registerNewUser(data)
        break
      case 'signOut':
        this.setState({ user: null })
        break
      case 'signUp':
        break
      default:
        break
    }
  }

  getUserData = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const { data } = await API.graphql(graphqlOperation(getUser, {
        id: user.attributes.sub
      }))
      if (user && data && data.getUser) {
        return this.getUserAttributes(user)
      }
    } catch(e) {
      // Not authenticated
      console.error(e)
    }
    this.setState({ user: null, started: true })
  }

  getUserAttributes = async (user) => {
    if (!user) {
      this.setState({ user: null })
      return
    }
    const attributesArr = await Auth.userAttributes(user)
    const attributesObj = Auth.attributesToObject(attributesArr)

    if (attributesObj && attributesObj.name) {
      user.displayName = attributesObj.name
    } else {
      user.displayName = user.username
    }
    this.setState({ user, userAttributes: attributesObj, started: true })
  }

  registerNewUser = async (signInData) => {
    if (!signInData || !signInData.signInUserSession || !signInData.signInUserSession.idToken) {
      console.error('Invalid data', signInData)
      return
    }
    const getUserInput = {
      id: signInData.signInUserSession.idToken.payload.sub,
      username: signInData.signInUserSession.idToken.payload.email
    }

    const { data } = await API.graphql(graphqlOperation(getUser, getUserInput))
    if (!data.getUser) {
      try {
        const createUserInput = {
          ...getUserInput,
          username: signInData.username,
          email: signInData.signInUserSession.idToken.payload.email,
          name: signInData.signInUserSession.idToken.payload.name,
          registered: true,
        }
        await API.graphql(graphqlOperation(createUser, { input: createUserInput }))
				window.location.reload()
      } catch (err) {
        console.error('Error registering new user', err)
      }
    }
  }

  handleSignout = async () => {
    try {
      await Auth.signOut()
      history.push('/')
    } catch (err) {
      console.error('Error signing out user', err)
    }
  }

  onClickLogin = () => {
    this.setState({ clickedLogin: true })
  }

  render() {
    const { user, userAttributes, clickedLogin, started } = this.state

    return (
      <MuiThemeProvider theme={theme}>
        <UserContext.Provider value={{ user, userAttributes }}>
          <Router history={history}>
            <>
              <Navbar user={user} onClickLogin={this.onClickLogin} handleSignout={this.handleSignout} />
              <main className='app-container'>
                { !started &&
                <Loading fullscreen={true} />
                }
                { started &&
                <Switch>
                  <Route
                    exact
                    path='/'
                    component={() => (
                      !user ?
                      <IntroPage onClickLogin={this.onClickLogin} />
                      :
                      <HomePage user={user} userAttributes={userAttributes} />
                    )} />
                  <Route
                    exact
                    path='/validate'
                    component={() => (
                      !user ?
                      <ValidatePage />
                      :
                      <Redirect to='/' />
                    )} />
                  {/* AUTHENTICATED */}
                  { user &&
                  <Route
                    path='/profile'
                    component={() => (
                      <ProfilePage
                        user={user}
                        userAttributes={userAttributes}
                      />
                    )}
                  />
                  }
                  { user &&
                  <Route
                    path='/studios/:studioId'
                    component={({ match }) => (
                      <StudioPage
                        user={user}
                        userAttributes={userAttributes}
                        studioId={match.params.studioId}
                      />
                    )}
                  />
                  }
                  { user &&
                  <Route
                    path='/projects/:projectId/:componentId'
                    component={({ match }) => (
                      <BlockPage
                        user={user}
                        userAttributes={userAttributes}
                        projectId={match.params.projectId}
                        componentId={match.params.componentId}
                        key={match.params.componentId}
                      />
                    )}
                  />
                  }
                  { user &&
                  <Route
                    path='/projects/:projectId'
                    component={({ match }) => (
                      <ProjectPage
                        user={user}
                        userAttributes={userAttributes}
                        projectId={match.params.projectId}
                        key={match.params.projectId}
                      />
                    )}
                  />
                  }
                  {/* <Route
                    path='/import'
                    component={ImportPage} /> */}
                  {/* NOT FOUND */}
                  <Route
                    component={NotFoundPage} />
                </Switch>
                }
              </main>
              <Footer />
              { clickedLogin && !user &&
                <AuthForm
                  open={clickedLogin}
                  onClose={() => { this.setState({ clickedLogin: false }) }}
                />
              }
            </>
          </Router>
        </UserContext.Provider>
      </MuiThemeProvider>
    )
  }
}

const theme = createTheme({
  // typography: {
  //   useNextVariants: true,
  // },
  // palette: {
  //   primary: {
  //     main: '#3caba5'
  //   },
  // },
})

export default App
