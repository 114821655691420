import React from 'react'
import { withRouter } from 'react-router-dom'
import { Storage, API, graphqlOperation } from 'aws-amplify'
import { Button, Icon, Tab, Statistic, Menu } from 'semantic-ui-react'

import StudioProjects from './StudioProjects'
import StudioMembers from './StudioMembers'
import StudioDialog from './StudioDialog'

import Loading from '../../components/ui/Loading'
import Notification from '../../components/ui/Notification'

import { getStudio } from '../../graphql/queries'
import { createUserStudios } from '../../graphql/mutations'

import '../../css/StudioPage.css'

class StudioPage extends React.Component {
	state = {
		studio: [],
		studioName: '',
		studioDescription: '',
		studioImgPreview: '',
		studioImage: '',
		studioOriginalImg: '',
		deleteStudioName: '',
		isLoading: true,
		isMember: false,
		isOwner: false,
		limitItems: 4,
		showLoadButton: true,
		updateStudioDialog: false,
		deleteStudioDialog: false,
		panes: [
			{
				menuItem: (
					<Menu.Item
						key='projects'
						onClick={() => this.setState({ showLoadButton: true })}>
						PROJECTS
					</Menu.Item>
				),
				render: () => (
					<Tab.Pane attached={false}>
						<StudioProjects
							studioProjects={this.state.studio.projects}
							limitItems={this.state.limitItems}
							isOwner={this.state.isOwner}
							studioId={this.props.studioId}
							studioData={this.state.studio}
						/>
					</Tab.Pane>
				),
			},
			{
				menuItem: (
					<Menu.Item
						key='members'
						onClick={() => this.setState({ showLoadButton: false })}
					>
						MEMBERS
					</Menu.Item>
				),
				render: () => (
					<Tab.Pane attached={false}>
						<StudioMembers
							studioData={this.state.studio}
							studioId={this.props.studioId}
						/>
					</Tab.Pane>
				),
			},
		],
	}

	componentDidMount() {
		this.handleGetStudio()
	}

	handleGetStudio = async () => {
		!this.state.isLoading && this.setState({ isLoading: true })

		const result = await API.graphql(graphqlOperation(getStudio, {
			id: this.props.studioId
		}))

		const { userAttributes } = this.props

		this.getStudioImg(result.data.getStudio.coverImage.key)
		this.setState({
			studio: result.data.getStudio,
			studioName: result.data.getStudio.name,
			studioDescription: result.data.getStudio.description,
			isLoading: false,
			isMember: result.data.getStudio.members.items.find(m => m.userID === userAttributes.sub) != null,
			isOwner: userAttributes && userAttributes.sub === result.data.getStudio.owner.id
		})
	}

	getStudioImg = async (img) => {
		let studioCoverImg = await Storage.get(img)
		this.setState({
			studioImgPreview: studioCoverImg,
			studioOriginalImg: studioCoverImg,
		})
	}

	onLoadMore = () => {
		this.setState({
			limitItems: this.state.limitItems + 10,
		})
	}

	confirmJoinStudio = async () => {
		if (window.confirm('Are you sure you want to join this studio?')) {
			const { user } = this.props
			const { studio } = this.state
			const res = await API.graphql(graphqlOperation(createUserStudios, {
				input: {
					userID: user.attributes.sub,
					studioID: studio.id
				}
			}))
			if (res && res.data && res.data.createUserStudios) {
				Notification({
					title: 'Success',
					message: 'Joined studio successfully!',
					type: 'success'
				})
				this.handleGetStudio()
			} else {
				Notification({
					title: 'Error',
					message: 'An unexpected error has occurred',
					type: 'error'
				})
			}
		}
	}

	onCloseDialog = async (studio) => {
		const state = { updateStudioDialog: false }
		if (studio && studio.id === this.props.studioId) {
			const imgUrlLink = await Storage.get(studio.coverImage.key)
			state.studio = studio
			state.imgLink = imgUrlLink
		}
		this.setState(state)
	}

	render() {
		const {
			studio,
			isLoading,
			panes,
			showLoadButton,
			limitItems,
			isMember,
			isOwner,
			updateStudioDialog,
		} = this.state

		return isLoading ? (
			<Loading fullscreen={true} />
		) : (
			<div>
				<div className='profile-page studio-page'>
					<div className='profile-background'>
						<div className='profile-container'>
							<div>
								<h1 className='profile-text profile-user' style={{display: 'inline-block', paddingRight: '1em'}}>{studio.name}</h1>
								{ isOwner &&
								<Button
									animated='vertical'
									onClick={() => this.setState({ updateStudioDialog: true })}>
									<Button.Content hidden>Edit</Button.Content>
									<Button.Content visible>
										<Icon name='edit' />
									</Button.Content>
								</Button>
								}
							</div>
							{ studio.description &&
							<p className='profile-text profile-intro'>
								Description: {studio.description}
							</p>
							}
							<Statistic.Group className='studio-data-group'>
								<Statistic className='first-data'>
									<Statistic.Label className='data-name'>
										Projects
									</Statistic.Label>
									<Statistic.Value className='data-sta'>
										{studio.projects.items.length}
									</Statistic.Value>
								</Statistic>
								<Statistic>
									<Statistic.Label className='data-name'>
										Members
									</Statistic.Label>
									<Statistic.Value className='data-sta'>
										{studio.members.items.length}
									</Statistic.Value>
								</Statistic>
							</Statistic.Group>
							{ !isMember &&
							<div className='project-buttons'>
								<Button
									circular
									className='project-btn'
									onClick={() => this.confirmJoinStudio()}>
									Join
								</Button>
							</div>
							}
							{ updateStudioDialog &&
								<StudioDialog
									studio={studio}
									onClose={this.onCloseDialog} />
							}
						</div>
					</div>

					<div className='profile-content'>
						<div className='profile-items'>
							<Tab
								menu={{
									secondary: true,
									pointing: true,
								}}
								panes={panes}
							/>
						</div>

						<div className='load-button'>
							{showLoadButton && studio.projects.items.length !== 0 ? (
								limitItems < studio.projects.items.length ? (
									<button
										className='ui button profile-button'
										onClick={this.onLoadMore}
									>
										Load More
									</button>
								) : (
									<button className='ui button disabled profile-button'>
										No More Projects
									</button>
								)
							) : (
								<div>
									<p></p>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(StudioPage)
