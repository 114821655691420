import React from 'react'
import { withRouter } from 'react-router-dom'
import { Storage, API, Auth, graphqlOperation } from 'aws-amplify'

import { Button, CircularProgress, TextField } from '@material-ui/core'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core'
import Notification from '../../components/ui/Notification'

import { DialogHeader } from '../ui/Dialog'
import ImagePicker from '../ui/ImagePicker'

import { createStudio, createUserStudios, updateStudio } from '../../graphql/mutations'
import { translateDataX } from '../../xAPI/DataX'
import { saveToLRS } from '../../xAPI'
import aws_exports from '../../aws-exports'

import './../../css/v2/Dialog.css'

class StudioDialog extends React.Component {
	state = {
		studio: this.props.studio,
		studioName: (this.props && this.props.studio && this.props.studio.name) || null,
		studioDescription: (this.props && this.props.studio && this.props.studio.description) || null,
		studioImage: null,
		studioImagePreview: (this.props && this.props.studio && this.props.studio.studioImagePreview) || null,
		saving: false
	}

	updateStudio = async () => {
		this.setState({ saving: true })
		const { studio, studioName, studioDescription, studioImage } = this.state

		try {
			const studioInput = {
				id: studio.id,
				name: studioName,
				description: studioDescription
			}
			const result = await API.graphql(
				graphqlOperation(updateStudio, { input: studioInput })
			)
			Notification({
				title: 'Success',
				message: 'Studio successfully updated!',
				type: 'success',
				duration: 2000,
			})
			this.setState({ saving: false })
			this.props.onClose && this.props.onClose(result.data.updateStudio)
		} catch (err) {
			this.setState({ saving: false })
			console.error(`Failed to update studio [${studio.id}]`, err)
		}
	}

	saveStudio = async () => {
		this.setState({ saving: true })

		const { studioName, studioDescription, studioImage } = this.state

		try {
			const { identityId } = await Auth.currentCredentials()

			// IMAGE
			let file = {
				key: '/public/default/codapmini-1.1bf4b74a.png',
				bucket: aws_exports.aws_user_files_s3_bucket,
				region: aws_exports.aws_project_region,
			}

			if (studioImage && studioImage.name) {
				const uploadedFile = await Storage.put(
					`/public/${identityId}/${Date.now()}-${studioImage.name}`,
					studioImage,
					{ contentType: studioImage.type }
				)
				if (uploadedFile && uploadedFile.key) {
					file.key = uploadedFile.key
				}
			}

			// SAVE STUDIO
			const user = await Auth.currentAuthenticatedUser()
			const studioInput = {
				name: studioName,
				description: studioDescription || '',
				coverImage: file,
				studioOwnerId: user.attributes.sub,
				type: 'Studio'
			}
			const studioResult = await API.graphql(
				graphqlOperation(createStudio, { input: studioInput })
			)
			await API.graphql(
				graphqlOperation(createUserStudios, {
					input: {
						userID: user.attributes.sub,
						studioID: studioResult.data.createStudio.id
					}
				})
			)
			saveToLRS(user.attributes, translateDataX('create', 'studio'), studioResult.data.createStudio.id)
			Notification({
				title: 'Success',
				message: 'Studio successfully created!',
				type: 'success',
			})
			this.setState({ saving: false })
			this.props.history.push(`/studios/${studioResult.data.createStudio.id}`)
		} catch (err) {
			this.setState({ saving: false })
			console.error('Error saving studio', err)
		}
	}

	onClose = (e) => {
		e.preventDefault()

		const {
			studioName,
			studioDescription,
			studioImage
		} = this.state

		if ((!studioName && !studioDescription && !studioImage) ||
			window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
			this.props.onClose && this.props.onClose()
		}
	}

	componentDidUpdate() {
		const {
			studioName,
			studioDescription,
			studioImage
		} = this.state

		if (studioName || studioDescription || studioImage) {
			window.onbeforeunload = () => true
		} else {
			window.onbeforeunload = undefined
		}
	}

	async componentDidMount() {
		if (this.state.studio && this.state.studio.file) {
			let imageURL = await Storage.get(this.state.studio.file.key)
			this.setState({ studioImagePreview: imageURL })
		}
	}

	componentWillUnmount() {
		window.onbeforeunload = undefined
	}

	render() {
		const {
			studio,
			studioName,
			studioDescription,
			studioImagePreview,
			saving
		} = this.state

		return (
			<Dialog
				open={true}
				className='create-studio'
				onClose={(event, reason) => {
					if (reason === 'backdropClick') {
						this.onClose(event, reason)
					}
				}}
				maxWidth='sm'
				fullWidth>
				<DialogHeader
					title={studio ? 'Update Studio' : 'Create New Studio'}
					onClose={this.onClose} />
				<DialogContent>
					<ImagePicker
						label='Studio Picture'
						preview={studioImagePreview}
						onChange={(image, preview) => this.setState({ studioImage: image, studioImagePreview: preview })} />
					<TextField
						label='Studio Name'
						type='name'
						variant='outlined'
						fullWidth
						style={styles.textField}
						defaultValue={studioName}
						error={studioName != null && studioName.length <= 0}
						onChange={e => { this.setState({ studioName: e.target.value }) }}
						required />
					<TextField
						label='Description'
						type='name'
						variant='outlined'
						fullWidth
						style={styles.textField}
						defaultValue={studioDescription}
						onChange={e => { this.setState({ studioDescription: e.target.value }) }} />
				</DialogContent>
				<DialogActions>
					{studio ?
						<Button
							autoFocus
							disabled={!studioName || !studioDescription || saving}
							onClick={this.updateStudio}>{saving ? <CircularProgress size={18} /> : 'Update Studio'}</Button>
						:
						<Button
							autoFocus
							disabled={!studioName || saving}
							onClick={this.saveStudio}>{saving ? <CircularProgress size={18} /> : 'Create Studio'}</Button>
					}
				</DialogActions>
			</Dialog>
		)
	}
}

const styles = {
	textField: {
		margin: '0.5em 0'
	}
}

export default withRouter(StudioDialog)
